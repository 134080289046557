import React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import { FaHeart } from 'react-icons/fa';
import "../styles/index.scss"


const Video = ({ videoSrcURL, videoTitle, width, height, ...props }) => (
    <div className="news-item-video">
        <iframe
            src={videoSrcURL}
            title={videoTitle}
            width={width}
            height={height}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
        />
    </div>
)

export default function Home() {
    return (
        <Layout>
            <div className="image-with-overlay">
                <StaticImage layout="fullWidth" src={"../images/skaters2.jpeg"} formats={["auto", "webp", "avif"]} />
                <div className="overlay" />

                <div className="app-screens-container" >
                    <StaticImage className="app-screens" src={"../images/iphone-views.png"} formats={["auto", "webp", "avif"]} />
                </div>

                <div className="logo-text-container">
                    <div className="logo-text">
                        <StaticImage className="white-logo" width={500} src={"../images/iskoll-wide-white.png"} formats={["auto", "webp", "avif"]} />
                        <div className="sub-text">Full koll på våra isar!</div>
                    </div>
                </div>

                <a href="https://app.iskoll.se" target="_blank" className="app-button" rel="noreferrer">
                    Till Iskolls App
                </a>
            </div>

            <div className="text-section">
                <h2>Vad är Iskoll?</h2>
                <p>Med Iskoll vill vi göra våra isar <em>tillgängligare för alla</em> genom att sprida information om vilka isar som används och vad man kan göra på dem.</p>
                <p>Vi gör det med hjälp av en app där du kan <em>dela med dig av vad du gör på isen</em> och beskriva isens kvalitet och bärighet. I appen kan du <em>se vad andra gör på isarna</em> runt om i Sverige. </p>
                <p>Du når <a href="https://app.iskoll.se" target="_blank" className="app-buttonx" rel="noreferrer">Iskoll på webben</a> och vi jobbar för fullt med att få klart appversionerna för iPhone och Android också.</p>
                <p>Följ oss på <a href="https://www.facebook.com/iskoll.se" target="_blank" rel="noreferrer">Facebook</a>! Då hjälper du till att sprida Iskoll, får senaste nytt och dessutom fina isbilder i ditt flöde <FaHeart style={{color:'#617091', marginRight:'2px'}}/><FaHeart style={{color:'#e5922c', marginRight:'2px'}}/><FaHeart style={{color:'#617091', marginRight:'2px'}}/></p>

                <h2>Vad Iskoll inte är</h2>
                <p>Iskoll är inte en app för issäkerhet eller livräddning. </p>
                <p>Iskolls rapporter skapas av personer med olika bakgrund och iserfarenhet, rapporterna ska användas som inspiration och indikation på vad som händer på våra isar. De ska inte användas som en garanti för vilka isar som kan användas eller inte. </p>
                <p>Som alltid gäller att du ska ha nödvändig kunskap, utrustning och sällskap när du går ut på en is.</p>
                <p>Iskoll är inte en ersättning för <a href="https://www.skridsko.net/">Skridskonätet</a>. Skridskonätet är en medlemsförening för alla som är med i en långfärdsskridskoklubb och innehåller mycket mer funktionalitet än vad Iskoll någonsin kommer att ha. Målgrupperna för Iskoll och Skridskonätet är inte identiska men grundsyftet är detsamma, att göra isaktiviteter roligare och säkrare.</p>

                <h2>Instruktioner</h2>
                <p>I appen finns en <a href="https://app.iskoll.se/faq" target="_blank" className="app-buttonx" rel="noreferrer">frågor/svar-lista</a> med allt du behöver veta för att komma igång.</p>
                <p>Föredrar du bilder istället för text så har vi en kort film som visar hur du lägger till en israpport.</p>
                <p>Video: <a href="https://youtu.be/ErIdwud3Hyg" target="_blank" className="app-buttonx" rel="noreferrer">Lägg till en israpport</a> (öppnas i nytt fönster)</p>


                <h2>Senaste nytt</h2>

                
                <div className="news-item">
                    <div className="news-item-date">14 januari 2023</div>
                    <div className="news-item-video-block">
                        <a href="https://www.facebook.com/iskoll.se/posts/pfbid03dyftYjkbmUjR2hS1nr7SwCJANC2fp72C69EMUNnnDJAyZnGCdbKqN9tpAeDUoR8l" target="_blank" rel="noreferrer">
                            <StaticImage className="news-item-img" src={"../images/english-promo.jpg"} formats={["auto", "webp", "avif"]} />
                        </a>
                        <div className="news-item-text">
                            <h3>Iskoll på engelska</h3>
                            <p>Nu kan även icke svenskspråkiga använda iskoll! </p>
                        </div>
                    </div>
                </div>

                <div className="news-item">
                    <div className="news-item-date">27 oktober 2022</div>
                    <div className="news-item-video-block">
                        <Video videoSrcURL="https://www.youtube.com/embed/mL0TOtvB1as" videoTitle="Iskolls höstnyheter" width="200" height="200" />
                        <div className="news-item-text">
                            <h3>Höstnytt</h3>
                            <p>Snabbfilter och användarprofiler är det senaste i Iskoll.</p>
                            <p>Kolla in klippet för att se vad det handlar om!</p>
                        </div>
                    </div>
                </div>

                <div className="news-item">
                    <div className="news-item-date">2 september 2022</div>
                    <div className="news-item-video-block">
                        <a href="https://www.facebook.com/iskoll.se/posts/pfbid034qMgy96AjhBHSGiswCgCzNuKivGXi7int7Wew4EASzDeuvxGtjvD89QVce6Phpf9l" target="_blank" rel="noreferrer">
                            <StaticImage className="news-item-img" src={"../images/likes-comments-promo.jpg"} formats={["auto", "webp", "avif"]} />
                        </a>
                        <div className="news-item-text">
                            <h3>Gilla och kommentera</h3>
                            <p>Nu kan du både gilla och kommentera israpporter. </p>
                        </div>
                    </div>
                </div>
                
                <div className="news-item">
                    <div className="news-item-date">6 augusti 2022</div>
                    <div className="news-item-video-block">
                        <a href="https://www.facebook.com/iskoll.se/posts/pfbid0TkfYqhAMZFj4TBUHjqurNV6avzFYuDv9Zu2qi8mHfEdcwT28NsYRm7DRcF9u854Nl" target="_blank" rel="noreferrer">
                            <StaticImage className="news-item-img" src={"../images/likes-promo.jpg"} formats={["auto", "webp", "avif"]} />
                        </a>
                        <div className="news-item-text">
                            <h3>Gilla rapporter!</h3>
                            <p>Visa vilka rapporter du tycker är bra genom att gilla dem.</p>
                        </div>
                    </div>
                </div>
                
                <div className="news-item">
                    <div className="news-item-date">23 maj 2022</div>
                    <div className="news-item-video-block">
                        <Video videoSrcURL="https://www.youtube.com/embed/xOeuGIy9o8w" videoTitle="Iskoll filterfunktion" width="200" height="200" />
                        <div className="news-item-text">
                            <h3>Ny funktionalitet i Iskollappen</h3>
                            <p>Nu finns stöd för filtrering av rapporter baserat på aktivitetstyp och rapportdatum. Det går också att välja olika färgkodningar på kartan, tex för att visa isens bärighet.</p>
                        </div>
                    </div>
                </div>
                
                <div className="news-item">
                    <div className="news-item-date">20 mars 2022</div>
                    <div className="news-item-video-block">
                        <Video videoSrcURL="https://www.youtube.com/embed/bQqGEfRRKGE" videoTitle="Iskoll säkerhetspromo" width="200" height="200" />
                        <div className="news-item-text">
                            <h3>Första versionen av Iskoll släpps</h3>
                            <p>Det här korta klippet visar appens grundfunktioner och hur issäkerheten kan förbättras med hjälp av den här typen av tjänst.</p>
                        </div>
                    </div>
                </div>

                <div style={{ height: '100px' }} />
            </div>



        </Layout>
    );
}